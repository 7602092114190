.mat-stroked-button {
  font-weight: 400;
  padding: 12px 30px;
  letter-spacing: 0;
  min-width: auto;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
}

.mat-button.btn,
.mat-raised-button.btn,
.mat-raised-button.btn:not([class*="mat-elevation-z"]),
.btn {
  position: relative;
  padding: 12px 30px;
  margin: $bmd-btn-margin-bottom 1px;
  min-width: auto;
  font-size: 0.75rem; //  12px
  font-weight: 400;
  line-height: $bmd-line-height;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  letter-spacing: 0;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-radius: $border-radius-sm;
  outline: 0;
  line-height: initial;
  @include undo-bs-tab-focus();

  //--
  // Colors
  @include bmd-raised-button-color();
  &.btn-white {
    &,
    &:focus,
    &:hover {
      background-color: $white-color;
      color: $gray-color;
    }
    &.btn-link {
      color: $white-color;
      background: transparent;
      box-shadow: none;
    }
  }
  &.btn-link:hover,
  &.btn-link:focus,
  &.btn-link:active {
    text-decoration: none !important;
  }

  @include hover-focus();

  //---
  // btn-raised
  &.btn-raised,
  .btn-group-raised & {
    // baseline shadow
    // @include box-shadow($bmd-shadow-2dp);

    // reverse any of the above for links
    &.btn-link {
      box-shadow: none;
      @include bmd-hover-focus-active() {
        box-shadow: none;
      }
    }

    @include bmd-disabled() {
      box-shadow: none;
    }
  }

  //---
  // btn-outline
  @include bmd-outline-button-color();

  // Size variations
  &.btn-lg,
  .btn-group-lg & {
    @include button-size(
      $input-btn-padding-y-lg,
      $input-btn-padding-x-lg,
      $bmd-btn-font-size,
      $btn-lg-line-height,
      $border-radius-sm
    );
  }
  &.btn-sm,
  .btn-group-sm & {
    @include button-size(
      $input-btn-padding-y-sm,
      $input-btn-padding-x-sm,
      $bmd-btn-font-size-sm,
      $line-height-sm,
      $border-radius-sm
    );
  }

  &.btn-round {
    border-radius: $border-radius-extreme;

    > .mat-button-focus-overlay,
    .mat-button-ripple {
      border-radius: $border-radius-extreme;
    }
  }

  &.btn-fab,
  &.btn-just-icon {
    // see above for color variations
    font-size: $mdb-btn-fab-font-size;
    height: $mdb-btn-fab-size;
    min-width: $mdb-btn-fab-size;
    width: $mdb-btn-fab-size;
    // margin: auto;
    padding: 0;
    overflow: hidden;
    position: relative;
    line-height: $mdb-btn-fab-size;

    &.btn-round {
      border-radius: 50%;
    }

    .btn-group-sm &,
    &.btn-sm,
    &.btn-fab-mini {
      height: $mdb-btn-fab-size-mini + 1;
      min-width: $mdb-btn-fab-size-mini + 1;
      width: $mdb-btn-fab-size-mini + 1;

      .material-icons,
      .fa {
        font-size: $mdb-btn-icon-size-mini;
        line-height: $mdb-btn-fab-size-mini;
      }
    }

    .btn-group-lg &,
    &.btn-lg {
      height: $mdb-btn-fab-size-lg + 1;
      min-width: $mdb-btn-fab-size-lg + 1;
      width: $mdb-btn-fab-size-lg + 1;
      line-height: $mdb-btn-fab-size-lg;

      .material-icons,
      .fa {
        font-size: $mdb-btn-icon-size;
        line-height: $mdb-btn-fab-size-lg;
      }
    }

    .material-icons,
    .fa {
      margin-top: 0;
      position: absolute;
      width: 100%;
      transform: none;
      left: 0;
      top: 0;
      height: 100%;

      line-height: $mdb-btn-fab-size;
      font-size: $mdb-btn-just-icon-font-size;
    }
  }
}

.btn-just-icon {
  &.btn-lg {
    font-size: $mdb-btn-fab-font-size;
    height: $mdb-btn-fab-size;
    min-width: $mdb-btn-fab-size;
    width: $mdb-btn-fab-size;
  }
}

.input-group-btn > .btn {
  border: 0;
}

// Align icons inside buttons with text
.btn .material-icons,
.btn:not(.btn-just-icon):not(.btn-fab) .fa {
  position: relative;
  display: inline-block;
  top: 0;
  margin-top: -1em;
  margin-bottom: -1em;
  font-size: 1.1rem;
  vertical-align: middle;
}

// Disabled buttons and button groups
.mat-raised-button.btn,
.input-group-btn .mat-raised-button.btn,
.btn-group,
.btn-group-vertical {
  // have to ratchet up the specificity to kill drop shadows on disabled raised buttons
  @include bmd-disabled() {
    .bg-inverse & {
      color: $bmd-inverse-btn-disabled;
    }

    // flat buttons shouldn't lose transparency on disabled hover/focus
  }
}

// btn-group variations
.btn-group,
.btn-group-vertical {
  position: relative;
  margin: 10px 1px;

  // spec: https://www.google.com/design/spec/components/buttons.html#buttons-toggle-buttons
  //&.open {
  //  .dropdown-toggle {
  //  }
  //
  //  > .dropdown-toggle.btn {
  //    @include bmd-raised-button-color-bg();
  //  }
  //}

  .dropdown-menu {
    border-radius: 0 0 $border-radius $border-radius;
  }

  &.btn-group-raised {
    @include box-shadow($bmd-shadow-2dp);
  }

  .mat-raised-button.btn + .mat-raised-button.btn,
  .mat-raised-button.btn,
  .mat-raised-button.btn:active,
  .btn-group {
    margin: 0;
  }

  // remove margin from nested btn-group(s) to properly align them with the outer buttons
  > .btn-group {
    margin: 0;
  }
}
.btn-group > .mat-raised-button.btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .mat-raised-button.btn,
.btn-group > .mat-raised-button.btn:not(:first-child) .mat-button-ripple,
.btn-group > .btn-group:not(:first-child) > .mat-raised-button.btn .mat-button-ripple,
.btn-group > .mat-raised-button.btn:not(:first-child) .mat-button-focus-overlay,
.btn-group > .btn-group:not(:first-child) > .mat-raised-button.btn .mat-button-focus-overlay {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group > .mat-raised-button.btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .mat-raised-button.btn,
.btn-group > .mat-raised-button.btn:not(:last-child):not(.dropdown-toggle) .mat-button-ripple,
.btn-group > .btn-group:not(:last-child) > .mat-raised-button.btn .mat-button-ripple,
.btn-group > .mat-raised-button.btn:not(:last-child):not(.dropdown-toggle) .mat-button-focus-overlay,
.btn-group > .btn-group:not(:last-child) > .mat-raised-button.btn .mat-button-focus-overlay {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-no-ripple .mat-button-ripple {
  display: none;
}
.mat-button,
.mat-icon-button {
  background: transparent;
}
.mat-button:hover .mat-button-focus-overlay,
.mat-stroked-button:hover .mat-button-focus-overlay {
  opacity: 0;
  background-color: transparent !important;
}
button:focus {
  outline: none;
}
.mat-button .mat-button-ripple {
  border-radius: inherit;
}

.btn {
  border-radius: 8px !important;
}
.btn-transparent {
  border: solid 1px #c0c0c0 !important;
  background: transparent !important;
  color: #c0c0c0 !important;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #47484a !important;
  text-transform: none;
}

.btn-black {
  background-color: #333333 !important;
}
