.timeline {
  width: 100%;
  height: 100px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.timeline .events {
  position: relative;
  background-color: #e9eaed;
  height: 4px;
  width: 100%;
  border-radius: 4px;
  margin: 3em 12.5%;
}

.timeline .events ol {
  margin: 0;
  padding: 0;
  text-align: center;
}

.timeline .events ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-right: -25%;
}

.timeline .events ul li {
  display: inline-block;
  width: 20%;
  margin: 0;
  padding: 0;
  position: relative;
  left: -10%;
  vertical-align:top;
  line-height: 13px;
}

.timeline .events ul li a {
  position: relative;
  bottom: -10px;
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: center;
  color: #646464;
  text-transform: uppercase;
}


@media (max-width: 1200px){
  .timeline .events ul li a {
    font-size: 9px;
  }
}

@media (max-width: 1010px){
  .timeline .events ul li a {
    font-size: 8px;
  }
}

@media (max-width: 714px){
  .timeline-container {
    display:none;
  }

  .progress-container {
    margin-top:30px;
  }
}

.timeline .events ul li a:hover::after {
  background-color: #194693;
  border-color: #194693;
}

.timeline .events ul li a.selected:after {
  background-color: #194693;
  border-color: #194693;
}

.events-content {
  width: 100%;
  height: 100px;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: left;
}

.events-content li {
  display: none;
  list-style: none;
}

.events-content li.selected {
  display: initial;
}

.events-content .events-content li h2 {
  font-weight: 500;
  color: #919191;
  font-size: 2.5em;
}

.events {
  &__ball {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid #e9eaed;
    margin: auto;
    position: relative;
    top: -7px;
    background: #fff;
    z-index: 0;

    img {
      max-width: 100%;
    }
  }

  &__status {
    &--finished {
      &:after {
        position: relative;
        background-color: $yellow-color;
        height: 4px;
        width: 100%;
        border-radius: 4px;
        content: "";
        display: block;
        top: 0;
        position: absolute;
        z-index: 0;
        left: 50%;
      }

      .events__ball {
        border: 0;
        top: -11px;
        z-index: 99;
      }
    }

    &--current {
      .events__ball {
        border: 2px;
        background-color: $yellow-color;
        border-color: $yellow-color;
        width: 16px;
        height: 16px;
        border: 0;
        top: -6px;

        &:after {
          content: "";
          width: 40px;
          height: 40px;
          background-color: #ffe600;
          display: block;
          opacity: 0.2;
          left: -11px;
          top: -11px;
          position: relative;
          border-radius: 50%;
        }
      }

      &:after {
        top: -8px;
        width: 0;
      }
    }
  }
}
