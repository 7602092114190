a.wesley-message-content-link:focus {
  outline: thin dotted;
}
a.wesley-message-content-link:focus:active,
a.wesley-message-content-link:focus:hover {
  outline: 0;
}
form.wesley-actions-text {
  margin: 0;
}
button.wesley-actions-buttons-button,
input.wesley-actions-text-input {
  margin: 0;
  font-size: 100%;
  line-height: normal;
  vertical-align: baseline;
}
button.wesley-actions-buttons-button::-moz-focus-inner,
input.wesley-actions-text-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
button.wesley-actions-buttons-button {
  cursor: pointer;
  -webkit-appearance: button;
}
.wesley-app-container {
  width: 100%;
  height: 100%;
  line-height: 1;
}
@media (min-width: 400px) {
  .wesley-app-container {
    width: 400px;
    height: 500px;
    margin: 0 auto;
  }
}
.wesley-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.wesley-message {
  /*margin: 10px 0;
  min-height: 20px;*/
  clear:both;
}
.wesley-message:after {
  display: block;
  content: "";
  clear: both;
}
.wesley-message-content {
  width: auto;
  max-width: 75%;
  display: inline-block;
}
.wesley-message-content.human {
  float: right;
}
.wesley-message-content iframe {
  width: 100%;
}
.wesley-message-content-image {
  margin: 5px 0;
  display: block;
  max-width: 200px;
  max-height: 200px;
}
.wesley-message-content-link {
  text-decoration: underline;
}
.profil {
  position: relative;
  border-radius: 50%;
}
.profil.human {
  float: right;
  margin: 10px 0 10px 10px;
}
.profil.agent {
  float: left;
  margin: 10px 10px 10px 0;
}
.profil > img {
  width: 26px;
  height: 26px;
  border-radius: 50%;
}
.profil > img.agent {
  content: url(/assets/img/wesley.png);
}
button.wesley-actions-buttons-button {
  margin-top: 10px;
  margin-bottom: 10px;
}
button.wesley-actions-buttons-button:not(:last-child) {
  margin-right: 10px;
}
@media (min-width: 400px) {
  .wesley-actions-text-submit {
    display: none;
  }
}
.wesley-container {
  font-size: 14px;
  background-color: #fff;
  font-family: "Montserrat", sans-serif;
}
.wesley-messages-container {
  padding: 10px 20px;
}
.wesley-actions-container {
  padding: 10px 20px;
}
.wesley-message {
  /*min-height: 30px;*/
}
.wesley-message-content {
  padding: 15px 23px;
  border-radius: 8px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.79;
  letter-spacing: normal;
  color: #626971;
  border-top-left-radius: 0;
  margin: 10px 0;
}
.wesley-message-content.human {
  color: #ffffff;
  background-color: #333333;
  border-top-left-radius: 8px;
  border-top-right-radius: 0;
}
.wesley-message-content.text {
  line-height: 1.3;
}
.wesley-message-content.loading {
  line-height: 1.3;
  text-align: center;
}
.wesley-message-content.embed {
  padding: 5px;
  border-radius: 5px;
}
.wesley-message-content-link {
  color: #919292;
}
.wesley-actions-text-input {
  border: 0;
  outline: 0;
  border-radius: 0;
  padding: 5px 7px;
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  color: #595a5a;
  border-bottom: 1px solid #919292;
}
.wesley-actions-text-submit {
  color: #fff;
  width: 30px;
  padding: 5px;
  height: 30px;
  line-height: 1;
  border-radius: 50%;
  border: 1px solid #919292;
  background: #777979;
}
.wesley-actions-buttons-button {
  border: 0;
  color: #fff;
  line-height: 1;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 7px 15px;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;
  background: #777979;
  box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.25);
}
.wesley-actions-text-select {
  border: 0;
  outline: 0;
  border-radius: 0;
  padding: 5px 7px;
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  color: #595a5a;
  border-bottom: 1px solid #919292;
}
.wesley-actions-text-searchselect {
  border: 0;
  outline: 0;
  border-radius: 0;
  padding: 5px 7px;
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  color: #595a5a;
  border-bottom: 1px solid #919292;
}
.wesley-actions-text-searchselect .dropdown-toggle {
  border: none !important;
}
.wesley-actions-text-searchselect .selected-tag {
  background-color: transparent !important;
  border: 0 !important;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}
.dot {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.5rem;
  display: inline-block;
  background-color: #919292;
}
.dot:nth-last-child(1) {
  margin-left: 0.3rem;
  animation: loading 0.6s 0.3s linear infinite;
}
.dot:nth-last-child(2) {
  margin-left: 0.3rem;
  animation: loading 0.6s 0.2s linear infinite;
}
.dot:nth-last-child(3) {
  animation: loading 0.6s 0.1s linear infinite;
}
@keyframes loading {
  0% {
    transform: translate(0, 0);
    background-color: #ababab;
  }
  25% {
    transform: translate(0, -3px);
  }
  50% {
    transform: translate(0, 0);
    background-color: #ababab;
  }
  75% {
    transform: translate(0, 3px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.wesley-messages {
  text-align: center;
  margin: 0px 34px;

  & > form {
    margin-bottom: 0;
  }
}

.user-button {
  color: #333333 !important;
  font-size: 14px !important;
  padding: 12px 30px !important;
  font-family: "Montserrat", sans-serif !important;
  line-height: initial !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  margin: auto;
  background-color: #ffe600 !important;
}

.wesley-messages .wesley-message-content {
  padding: 7px;
}

.wesley-message-content-user-input {
  padding: 15px 23px;
  border-radius: 8px;
  width:100%;
}

.button-container {
  margin-top: 10px;
}
.mat-form-field-infix {
  width: auto !important;
}

.wesley-answers-margin {
  margin: 10px;
  padding: 0!important;
}

.wesley-answers-margin form {
margin:0;
}
.wesley-step7-content {
  max-width:100%;
}
