.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 260px;
  background: $black-color;
  @include shadow-big();

  .sidebar-wrapper {
    position: relative;
    height: calc(100vh - 75px);
    overflow: auto;
    width: 260px;
    z-index: 4;
    padding-bottom: 30px;
  }

  .nav {
    margin-top: 20px;
    display: block;

    li {
      > a {
        &:hover,
        &:focus {
          background-color: transparent;
          outline: none;
        }
      }

      &:hover > a,
      & .dropdown-menu a:hover,
      & .dropdown-menu a:focus,
      &.active > [data-toggle="collapse"] {
        background-color: #2c2c2c;
        color: #fff;
        box-shadow: none;
      }

      &.active > [data-toggle="collapse"] {
        i {
          color: #a9afbb;
        }
      }

      &.active > a,
      &.active > a i {
        background-color: #2c2c2c;
        color: $white-color;

        g {
          //fill: $yellow-color;
        }
      }
    }

    p {
      margin: 0;
      line-height: 30px;
      font-size: 14px;
      position: relative;
      display: block;
      height: auto;
      white-space: nowrap;
    }

    i {
      font-size: 24px;
      float: left;
      margin-right: 15px;
      line-height: 30px;
      width: 30px;
      text-align: center;
      color: #a9afbb;
    }
  }

  .nav {
    &-link {
      margin: 0;
      color: #fff;
      font-size: 13px;
      padding: 20px 30px;
      color: #fff;
      font-weight: normal;

      &:hover,
      &:focus {
        background-color: transparent;
        outline: none;

        g {
          // fill: $yellow-color;
        }
      }

      &.disabled {
        opacity: 0.4;
      }
    }

    &-item {
      &__icon {
        display: inline-block;
        margin-right: 20px;

        g {
          //  fill: #616161;
        }
      }

      &__description {
        display: inline-block;
        padding-right: 10px;
      }
    }
  }

  .logo {
    margin: 0;
    display: block;
    position: relative;
    z-index: 4;

    &__holder {
      padding: 32px 37px;
      display: block;
    }
    &__image {
      max-width: 65px;
      width: 100%;
      display: inline-block;
    }
    &__text {
      display: inline-block;
      font-weight: normal;
      color: #fff;
      font-size: 22px;
      float: right;
      margin-top: 20px;

      &--yellow {
        color: $yellow-color;
      }

      &--env {
        font-size: 8px;
        color: white;
        vertical-align: sub;
      }
    }

    .logo-holder {
      text-transform: uppercase;
      padding: $padding-small-vertical $padding-zero;
      display: inline-block;
      font-size: 18px;
      color: $black-color;
      white-space: nowrap;
      font-weight: $font-weight-default;
      line-height: 30px;
      overflow: hidden;
      text-align: center;
      display: block;
    }
  }

  &[data-color="purple"] {
    @include set-background-color-button($brand-primary);
  }
  &[data-color="azure"] {
    @include set-background-color-button($brand-info);
  }
  &[data-color="green"] {
    @include set-background-color-button($brand-success);
  }
  &[data-color="orange"] {
    @include set-background-color-button($brand-warning);
  }
  &[data-color="danger"] {
    @include set-background-color-button($brand-danger);
  }
  &[data-color="rose"] {
    @include set-background-color-button($brand-rose);
  }

  &[data-color="white"] {
    @include set-background-color-button($white-color);
    @include sidebar-active-color($black-color);
  }

  &[data-image]:after,
  &.has-image:after {
    opacity: 0.77;
  }
}

.off-canvas-sidebar .navbar-collapse {
  .nav {
    > li > a,
    > li > a:hover {
      color: $white-color;
      margin: 0 15px;
    }

    > li > a:focus,
    > li > a:hover {
      background: #2c2c2c;
    }
  }
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.main-panel {
  position: relative;
  float: right;
  width: $sidebar-width;
  @include transition(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  height: calc(100% - 64px) !important;
  margin-top: 64px;
  padding: 0;

  .footer {
    border-top: 1px solid #e7e7e7;
  }

  .navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    margin-bottom: 0;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    background-color: #fff !important;
    padding: 0;
    z-index: 2;
  }

  .header {
    margin-bottom: 30px;

    .title {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.nav-open .main-panel {
  height: 100% !important;
  margin-top: 0;
}

.perfect-scrollbar-on {
  .sidebar,
  .main-panel {
    height: 100%;
    max-height: 100%;
  }

  .sidebar {
    z-index: 3;
  }
}

.sidebar,
.main-panel,
.sidebar-wrapper {
  -webkit-transition-property: top, bottom, width;
  transition-property: top, bottom, width;
  -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
  transition-duration: 0.2s, 0.2s, 0.35s;
  -webkit-transition-timing-function: linear, linear, ease;
  transition-timing-function: linear, linear, ease;
  -webkit-overflow-scrolling: touch;
}

.visible-on-sidebar-regular {
  display: inline-block !important;
}
.visible-on-sidebar-mini {
  display: none !important;
}

@media (min-width: 991px) {
  .sidebar-mini {
    .visible-on-sidebar-regular {
      display: none !important;
    }
    .visible-on-sidebar-mini {
      display: inline-block !important;
    }

    .sidebar,
    .sidebar .sidebar-wrapper {
      width: 80px;
    }

    .main-panel {
      width: $sidebar-mini-width;
    }

    .sidebar {
      display: block;
      font-weight: 200;
      z-index: 9999;

      .logo {
        a.logo-normal {
          opacity: 0;
          @include transform-translate-x(-25px);
        }
      }

      .sidebar-wrapper {
        > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
        .user .user-info [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
        .user .user-info > a > span,
        > .nav li > a p {
          @include transform-translate-x(-25px);
          opacity: 0;
        }
      }
    }

    .sidebar:hover {
      width: 260px;

      .logo {
        a.logo-normal {
          opacity: 1;
          @include transform-translate-x(0px);
        }
      }

      .sidebar-wrapper {
        width: 260px;

        > .nav li > a p,
        > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
        .user .user-info [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
        .user .user-info > a > span {
          @include transform-translate-x(0px);
          opacity: 1;
        }
      }
    }
  }

  .sidebar {
    .nav .nav-item {
      &.active-pro {
        position: absolute;
        width: 100%;
        bottom: 13px;
      }
    }
  }
}

.consultant {
  padding: 20px 40px;

  &__title {
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #646464;
    margin-bottom: 20px;
  }

  &__person {
    vertical-align: middle;
  }

  &__avatar {
    width: 24px;
    height: 24px;
    border-radius: 180px;
  }

  &__name {
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    display: inline-block;
    margin-left: 20px;
  }
}

.nav__bottom {
  margin-top: 40px;

  .nav-link {
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #646464;
  }
}

.wesley-progress {
  &__percent {
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
    display: inline-block;
    width: 100px;
    vertical-align: middle;
  }

  &__bar {
    border-radius: 4px;
    background-color: #f0f0f0;
    width: calc(100% - 360px) !important;
    display: inline-block !important;
  }

  &__button {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    width: 220px;
    display: inline-block;
    float: right;
    border-radius: 8px;
    border: solid 1px #c0c0c0;

    img {
      margin-right: 10px;
    }
  }

  &--dashboard {
    .wesley-progress__percent {
      font-size: 50px;
      width: 160px;
    }

    .mat-progress-bar {
      height: 8px;
    }

    .wesley-progress__bar {
      width: calc(100% - 600px) !important;
    }

    .btn {
      margin: 10px !important;
    }
  }
}

.button-holder {
  float: right;
}

@media (max-width: 768px) {
  .wesley-progress--dashboard .wesley-progress__bar {
    width: calc(100% - 170px) !important;
  }

  .progress-container a {
    float: initial !important;
    margin: 0;
  }

  .button-holder {
    float: initial;
    text-align: center;
  }
}

.mat-progress-bar-buffer {
  background-color: #f0f0f0;
}
.mat-progress-bar-fill::after {
  background-color: $yellow-color !important;
}

.navbar-toggler {
  box-shadow: none !important;
  border: 0;
}
