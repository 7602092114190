.card-stats {
  .card-contents {
    margin-top: 20px;

    .btn {
      float: right;
    }
  }

  .card-header {
    &.card-header-icon,
    &.card-header-text {
      text-align: left;
    }

    .card-icon {
      margin-right: 20px;
    }
    .card-icon + .card-category {
      padding-top: 10px;
    }

    &.card-header-icon .card-category,
    &.card-header-text .card-category {
      margin: 0;
    }
    .card-category {
      margin-bottom: 0;
      margin-top: 0;
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #333333;
      padding-top: 7px;

      &--small {
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333333;
      }

      &--bigger {
        font-size: 24px;
        font-weight: 500;
      }
    }

    & + .card-footer {
      border-top: 1px solid #eee;
      margin-top: 14px;
    }

    &.card-header-icon i {
      font-size: 36px;
      line-height: 56px;
      width: 56px;
      height: 56px;
      text-align: center;
    }
  }

  .card-body {
    text-align: right;
  }
}

.attachments {
  display: inline-block;
  vertical-align: bottom;
  margin-top: 15px;

  &__digit {
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #47484a;
    &--last {
      font-weight: 400;
    }
  }

  &__of {
    font-size: 22px;
    color: #c0c0c0;
  }
}
.card-small-margin {
  margin:30px 0 0 0;
}