@use '@angular/material' as mat;
@import "@angular/material/prebuilt-themes/indigo-pink.css";

@import "core/variables";
@import "core/mixins";
@import "~bootstrap/scss/bootstrap";

// Core Components
@import "core/buttons";
@import "core/checkboxes";
@import "core/radios";
@import "core/forms";
@import "core/input-group";
@import "core/images";
@import "core/navbar";
@import "core/alerts";
@import "core/type";
@import "core/tabs";
@import "core/tooltip";
@import "core/popover";
@import "core/dropdown";
@import "core/togglebutton";
@import "core/ripples";
@import "core/footers";
@import "core/timeline";
@import "core/sidebar-and-main-panel";
@import "core/fixed-plugin";
@import "core/tables";
@import "core/misc";
@import "core/chatbot";

@import "core/cards";
@import "core/cards/card-stats";
@import "core/cards/card-profile";
@import "core/cards/card-plain";

//calendar
@import "~angular-calendar/css/angular-calendar.css";

//plugin scss
@import "core/plugins/animate";
@import "core/plugins/perfect-scrollbar";

@import "core/responsive";

@import "core/angular-modal.scss";


@include mat.core();

$app-primary: mat.define-palette(mat.$grey-palette, 600);
$app-accent: mat.define-palette(mat.$yellow-palette, 600);
$app-warn: mat.define-palette(mat.$red-palette, 600);

$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);

@include mat.all-component-themes($app-theme);

.debug {
  background-color: #adadad;
  color: black;
}
.language--flag {
  width: 22px;
  vertical-align: text-top;
  margin-right: 10px;
  height: 14px;
}
